* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}

@font-face {
  font-family: "Circular";
  src: url(./asset/CircularStd-Medium.ttf);
}

:root {
  font-size: 11px;
}

body {
  background: #000000;
  min-height: 100vh;
  font-family: "Circular";
  color: #fff;
}

.App {
  padding: 4% 6%;
  padding-top: 5%;
  padding-bottom: 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-gap: 3.875rem;
  margin-bottom: 3.875rem;
  grid-template-rows: 34.5rem;
  max-width: 111.9375rem;
}

.footer {
  max-width: 111.9375rem;
  margin: auto;
}

.brand {
  font-weight: 900;
  font-size: 4.375rem;
  line-height: 5.5625rem;
  color: #ffffff;
}

.span-2 {
  grid-column: 1/3;
}

.span-3 {
  grid-column: 1/4;
}

.smText {
  font-weight: 450;
  font-size: 3.125rem;
  line-height: 140%;
  color: #afafaf;
  max-width: 30ch;
  margin-top: 1.875rem;
}

.container > div {
  background: #121212;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10%;
}

button {
  background-color: transparent;
  font-family: inherit;
  border: none;
  outline: none;
}

.support {
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #000000;
  background: #ffffff;
  border-radius: 0.625rem;
  padding: 0.75rem 2rem;
  padding-top: 1rem;
  cursor: pointer;
  width: max-content;
  margin: auto;
}

.map {
  background: url("./asset/WorldMap.png") !important;
  background-size: cover !important;
  background-color: #121212 !important;
}

.item p {
  font-weight: 450;
  font-size: 2.5rem;
  line-height: 3.1875rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1.5rem;
}

.item span {
  color: #afafaf;
  font-size: 2.4rem;
}

.item img {
  height: 17.625rem;
  object-fit: contain;
}

.center span {
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 450;
  font-size: 10.625rem;
  line-height: 13.4375rem;
  color: #ffffff;
}

.animated-container {
  justify-content: center;
}

.center p {
  text-align: center;
  font-weight: 450;
  font-size: 2.5rem;
  line-height: 3.1875rem;
  text-align: center;
  max-width: 36ch;
  margin: auto;
  color: #898989;
  margin-top: 0.875rem;
}

.dash {
  background: url("./asset/Dash.png") !important;
  background-color: transparent !important;
  background-size: cover !important;
}

.dash span {
  font-weight: 900;
  font-size: 3.125rem;
  line-height: 3.9375rem;
  color: #ffffff;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
  margin-top: -6rem;
}

.dash span:first-child {
  margin-right: 1rem;
}

.dash span:last-child {
  font-size: 3.125rem;
  line-height: 3.9375rem;
  font-weight: 400;
  color: #898989;
}

.dash button {
  margin-top: 2rem;
}

.bottom {
  margin-top: 2.9375rem;
  font-weight: 900;
  font-size: 1.875rem;
  line-height: 2.375rem;
  color: #ffffff;
}

.bottom a {
  margin-left: 4.125rem;
  color: #fff;
  text-decoration: none;
}

.footerContainer {
  background: #121212;
  padding: 4% 6%;
  padding-top: 6%;
}

button {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

button:hover {
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
}

button:active {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
}

.logo {
  object-fit: contain;
  height: 64px;
  max-width: 100%;
}

.upcoming img {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: 8px;
  }
}

@media screen and (max-width: 850px) {
  .container {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 3.875rem;
    grid-template-rows: auto;
  }

  .support {
    font-size: 2.125rem;
    line-height: 2.5rem;
    padding: 1.375rem 3.25rem;
  }

  .span-2 {
    min-height: 25.4375rem;
  }

  .map {
    min-height: 15.625rem;
  }

  .container:nth-child(3) {
    min-height: 21.25rem;
  }

  .dash {
    min-height: 21.25rem;
    background-position: 18% 10% !important;
  }

  .itemsContainer {
    display: flex;
    align-items: center;
    overflow-x: auto;
  }

  .item {
    flex-basis: 30rem;
    flex-shrink: 0;
    margin-right: 1.5rem;
  }

  .footerContainer {
    padding-top: 5rem;
  }

  .smText {
    max-width: 16ch;
  }

  .bottom {
    padding-bottom: 3.875rem;
  }

  .bottom a {
    display: block;
    margin-left: 0;
    margin-top: 2rem;
  }

  .flex {
    margin-top: -1rem;
  }

  .dash span:first-child {
    font-size: 1.875rem;
    line-height: 1.5625rem;
  }

  .dash span:last-child {
    font-size: 1.758rem;
    line-height: 1.5625rem;
  }

  .dash button {
    margin-top: 2.5rem;
  }

  .logo {
    height: 35px;
  }
}

.footer a {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.footer a:hover {
  opacity: 0.5;
}
